<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div class="dF">
			<a-icon type="arrow-left" @click="$router.go(-1)" />
		</div>
		<div>
			<a-tabs size="large" default-active-key="1">
				<div slot="tabBarExtraContent" class="dF" style="gap: 20px">
					<div class="dF" style="gap: 5px">
						<span>Download:</span>
						<a @click.prevent="getCSV(dataRecords)" href="javascript:;" class="text-primary">CSV</a>
					</div>
				</div>
				<a-tab-pane key="1" tab="Inventory Details">
					<a-card class="table-card">
						<a-table class="tableStyle" :columns="columns" :data-source="dataRecords"
							:row-key="(record) => record.id + '_' + record.lot" :scroll="{ x: 1200 }">
							<template #conditionPremium="conditionPremium">
								<div class="dF aC" v-if="conditionPremium.premiums.length">
									<a-tooltip overlayClassName="change-tooltip-color" :title="conditionPremium.premiums.join(
										',  '
									)
										" class="dF aC">
										<div>
											{{ conditionPremium.firstPremium }}
										</div>
										<div v-if="conditionPremium.premiums
											.length > 1
											" class="px-2 ml-3" style="
                                                background-color: #f7f5f9;
                                                border-radius: 6px;
                                                border: 1px solid #d0c9d6;
                                            ">
											{{
												`${conditionPremium.premiums.length}+`
											}}
										</div>
									</a-tooltip>
								</div>
								<span v-else>N/A</span>
							</template>
							<template #premium="premium">
								<div class="dF aC" v-if="premium.premiums.length">
									<a-tooltip overlayClassName="change-tooltip-color"
										:title="premium.premiums.join(',  ')" class="dF aC">
										<div>
											{{ premium.firstPremium }}
										</div>
										<div v-if="premium.premiums.length > 1" class="px-2 ml-3" style="
                                                background-color: #f7f5f9;
                                                border-radius: 6px;
                                                border: 1px solid #d0c9d6;
                                            ">
											{{ `${premium.premiums.length}+` }}
										</div>
									</a-tooltip>
								</div>
								<span v-else>N/A</span>
							</template>
							<template #addOn="addOn">
								<div class="dF aC" v-if="addOn.addOns.length">
									<a-tooltip overlayClassName="change-tooltip-color" :title="addOn.addOns.join(',  ')"
										class="dF aC">
										<div>
											{{ addOn.firstAddOn }}
										</div>
										<div v-if="addOn.addOns.length > 1" class="px-2 ml-3" style="
                                                background-color: #f7f5f9;
                                                border-radius: 6px;
                                                border: 1px solid #d0c9d6;
                                            ">
											{{ `${addOn.addOns.length}+` }}
										</div>
									</a-tooltip>
								</div>
								<span v-else>N/A</span>
							</template>
							<template #lotPremium="lotPremium">
								<span v-if="lotPremium">
									{{ `$${lotPremium.toLocaleString("US")}` }}
								</span>
							</template>
							<template #elevationPrice="elevationPrice">
								<span v-if="elevationPrice">
									{{
										`$${elevationPrice.toLocaleString(
											"US"
										)}`
									}}
								</span>
							</template>
							<template #totalPrice="totalPrice">
								<span v-if="totalPrice">
									{{ `$${totalPrice.toLocaleString("US")}` }}
								</span>
							</template>
							<template #premiumPrice="premiumPrice">
								<span v-if="premiumPrice">
									{{
										`$${premiumPrice.toLocaleString("US")}`
									}}
								</span>
							</template>
							<template #addOnPrice="addOnPrice">
								<span v-if="addOnPrice">
									{{ `$${addOnPrice.toLocaleString("US")}` }}
								</span>
							</template>
							<template #unitPrice="unitPrice">
								<span v-if="unitPrice">
									{{ `$${unitPrice.toLocaleString("US")}` }}
								</span>
							</template>
							<template #closingDate="closingDate">
								{{ formatDate(closingDate) }}
							</template>
						</a-table>
					</a-card>
				</a-tab-pane>
			</a-tabs>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { formatDate } from 'bh-mod'

export default {
	data: () => ({
		dataRecords: [],
		loading: true,
	}),

	computed: {
		...mapState([
			"selectedChart",
			"records",
			"currentStartDate",
			"currentEndDate",
			"instance"
		]),

		columns() {
			return this.getColumns();
		},

		filename() {
			return `Inventory Report(${moment(this.currentStartDate).format(
				"ll"
			)} - ${moment(this.currentEndDate).format("ll")})`;
		},

		corporateInstance() {
			return (this.instance && this.instance.projectType === 'corporate' && this.instance.children.length)
		},

		selectedProjects() {
			return this.$store.state.selectedProjects
		},
	},

	created() {
		this.loading = false;
	},

	methods: {
		formatDate,

		getColumns() {
			if (
				[
					"Lot inventory by product type",
					"Lot inventory by status",
				].includes(this.selectedChart.title)
			) {
				this.dataRecords = this.records.inventory.lots.map((l) => {
					return {
						...l,
						closingDate: l.closingDate && formatDate(l.closingDate) || "",
					}
				});
				if (this.corporateInstance) {
					this.dataRecords = this.dataRecords.filter(r => this.selectedProjects.includes(r.instanceName))
				}

				return [
					{
						title: "Project",
						dataIndex: "instanceName",
						key: "instanceName",
						sorter: (a, b) => a.instanceName.localeCompare(b.instanceName),
					},
					{
						title: "Lot #",
						dataIndex: "lot",
						key: "lot",
						sorter: (a, b) => parseInt(a.lot?.replace('Lot', '')) - parseInt(b.lot?.replace('Lot', '')),
					},
					{
						title: "Lot frontage",
						dataIndex: "frontage",
						key: "frontage",
						sorter: (a, b) => a.frontage - b.frontage,
					},
					{
						title: "Lot type",
						dataIndex: "lotType",
						key: "lotType",
						sorter: (a, b) => a.lotType.localeCompare(b.lotType),
					},
					{
						title: "Lot status",
						dataIndex: "lotStatus",
						key: "lotStatus",
						sorter: (a, b) => a.lotStatus.localeCompare(b.lotStatus),
					},
					{
						title: "Condition/Premium",
						dataIndex: "conditionPremium",
						key: "conditionPremium",
						scopedSlots: { customRender: "conditionPremium" },
						sorter: (a, b) => a.conditionPremium.firstPremium.localeCompare(b.conditionPremium.firstPremium),
					},
					{
						title: "Lot Premium",
						dataIndex: "lotPremium",
						key: "lotPremium",
						scopedSlots: { customRender: "lotPremium" },
						sorter: (a, b) => a.lotPremium - b.lotPremium,
					},
					{
						title: "Tentative/Firm Close Date",
						dataIndex: "closingDate",
						key: "closingDate",
						scopedSlots: { customRender: "closingDate" },
						sorter: (a, b) => ((a.closingDate || 0) - (b.closingDate || 0)),
					}
				];
			} else if (
				this.selectedChart.title ===
				"Standing inventory value by model/elevation"
			) {
				this.dataRecords = this.records.inventory.elevations.map((l) => {
					return {
						...l,
						closingDate: l.closingDate && formatDate(l.closingDate) || "",
					}
				});
				if (this.corporateInstance) {
					this.dataRecords = this.dataRecords.filter(r => this.selectedProjects.includes(r.instanceName))
				}

				return [
					{
						title: "Project",
						dataIndex: "instanceName",
						key: "instanceName",
						sorter: (a, b) => a.instanceName.localeCompare(b.instanceName),
					},
					{
						title: "Model #",
						dataIndex: "model",
						key: "model",
						sorter: (a, b) => a.model.localeCompare(b.model),
					},
					{
						title: "Model/Elevation",
						dataIndex: "modelElevation",
						key: "modelElevation",
						sorter: (a, b) => a.modelElevation.localeCompare(b.modelElevation),
					},
					{
						title: "Model type",
						dataIndex: "modelType",
						key: "modelType",
						sorter: (a, b) => a.modelType.localeCompare(b.modelType),
					},
					{
						title: "Frontage",
						dataIndex: "frontage",
						key: "frontage",
						sorter: (a, b) => a.frontage - b.frontage,
					},
					{
						title: "Lot #",
						dataIndex: "lot",
						key: "lot",
						sorter: (a, b) => parseInt(a.lot?.replace('Lot', '')) - parseInt(b.lot?.replace('Lot', '')),
					},
					{
						title: "Condition/Premium",
						dataIndex: "conditionPremium",
						key: "conditionPremium",
						scopedSlots: { customRender: "conditionPremium" },
						sorter: (a, b) => a.conditionPremium.firstPremium.localeCompare(b.conditionPremium.firstPremium),
					},
					{
						title: "Lot Premium",
						dataIndex: "lotPremium",
						key: "lotPremium",
						scopedSlots: { customRender: "lotPremium" },
						sorter: (a, b) => a.lotPremium - b.lotPremium,
					},
					{
						title: "Elevation Price",
						dataIndex: "elevationPrice",
						key: "elevationPrice",
						scopedSlots: { customRender: "elevationPrice" },
						sorter: (a, b) => a.elevationPrice - b.elevationPrice,
					},
					{
						title: "Total Price",
						dataIndex: "totalPrice",
						key: "totalPrice",
						scopedSlots: { customRender: "totalPrice" },
						sorter: (a, b) => a.totalPrice - b.totalPrice,
					},
					{
						title: "Tentative/Firm Close Date",
						dataIndex: "closingDate",
						key: "closingDate",
						scopedSlots: { customRender: "closingDate" },
						sorter: (a, b) => ((a.closingDate || 0) - (b.closingDate || 0)),
					}
				];
			} else if (
				[
					"Inventory by unit status",
					"Lot inventory by unit type",
					"Inventory value by unit type",
				].includes(this.selectedChart.title)
			) {
				this.dataRecords = this.records.inventory.units;
				if (this.corporateInstance) {
					this.dataRecords = this.dataRecords.filter(r => this.selectedProjects.includes(r.instanceName))
				}

				return [
					{
						title: "Project",
						dataIndex: "instanceName",
						key: "instanceName",
						sorter: (a, b) => a.instanceName.localeCompare(b.instanceName),
					},
					{
						title: "Unit #",
						dataIndex: "number",
						key: "number",
						sorter: (a, b) => a.number - b.number,
					},
					{
						title: "Unit Name",
						dataIndex: "name",
						key: "name",
						sorter: (a, b) => a.name.localeCompare(b.name),
					},
					{
						title: "Unit Type",
						dataIndex: "type",
						key: "type",
						sorter: (a, b) => a.type.localeCompare(b.type),
					},
					{
						title: "Unit status",
						dataIndex: "status",
						key: "status",
						sorter: (a, b) => a.status.localeCompare(b.status),
					},
					{
						title: "Unit Premium",
						dataIndex: "premium",
						key: "premium",
						scopedSlots: { customRender: "premium" },
					},
					{
						title: "Unit Add On",
						dataIndex: "addOn",
						key: "addOn",
						scopedSlots: { customRender: "addOn" },
					},
					{
						title: "Unit Premium Price",
						dataIndex: "premiumPrice",
						key: "premiumPrice",
						scopedSlots: { customRender: "premiumPrice" },
						sorter: (a, b) => a.premiumPrice - b.premiumPrice,
					},
					{
						title: "Add On Price",
						dataIndex: "addOnPrice",
						key: "addOnPrice",
						scopedSlots: { customRender: "addOnPrice" },
						sorter: (a, b) => a.addOnPrice - b.addOnPrice,
					},
					{
						title: "Unit Price",
						dataIndex: "unitPrice",
						key: "unitPrice",
						scopedSlots: { customRender: "unitPrice" },
						sorter: (a, b) => a.unitPrice - b.unitPrice,
					},
					{
						title: "Total Price",
						dataIndex: "totalPrice",
						key: "totalPrice",
						scopedSlots: { customRender: "totalPrice" },
						sorter: (a, b) => a.totalPrice - b.totalPrice,
					},
				];
			}
		},

		getCSV(records, filename = this.filename) {
			if (records.length) {
				let csv = Object.keys(records[0]).join(",");
				csv += "\n";
				records.forEach((row) => {
					csv += Object.values(row)
						.map((r) => {
							if (typeof r === "string") {
								return r.replaceAll(", ", " & ");
							}
							if (typeof r === "object") {
								if (r.premiums && r.premiums.length) {
									return r.premiums
										.map((rp) => {
											return rp.replaceAll(",", "");
										})
										.join(" & ");
								}
								if (r.addOns && r.addOns.length) {
									return r.addOns
										.map((rp) => {
											return rp.replaceAll(",", "");
										})
										.join(" & ");
								}
								if (r.length) {
									return r
										.map((rp) => {
											if (typeof rp === "string") {
												return rp
													.replaceAll(", ", " & ")
													.replaceAll(",", "");
											}
											return rp;
										})
										.join(" => ");
								}
								return "";
							}
							return r;
						})
						.join(",");
					csv += "\n";
				});

				const anchor = document.createElement("a");
				anchor.href =
					"data:text/csv;charset=utf-8," + encodeURIComponent(csv);
				anchor.target = "_blank";
				anchor.download = `${filename}.csv`;
				anchor.click();
			} else {
				this.$message.info("No records found!");
			}
		},
	},
};
</script>
